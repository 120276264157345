<template>
<section id="livestatus" class="hero is-large has-text-centered">
  <!-- HEADER HALO LOGO -->
  <div class="hero-head">
    <img src="./../assets/stplus.png" width="20%"/>
    <div class="field" >
      <label class="label stblue-foreground calibri font-size-200 m-t-12">{{headline}}</label>
    </div>
  </div>
  <div class="column is-half is-offset-one-quarter">
    <!-- LOGIN BOX -->
    <div class="box needs-padding is-border-green has-background-white" v-if="!logged">
      <div class="container">
        <div class="container">
          <article class="message is-danger" v-if="logerror">
            <div class="message-header">
              <p>Authentication Error</p>
              <button class="delete" aria-label="error" @click="closeLogErrorMsg"></button>
            </div>
            <div class="message-body">
              The username and password do not match with our records
            </div>
          </article>
          <!-- <form> -->
            <div class="field">
              <div class="control">
                <input class="input" type="text" v-model="user" placeholder="Username" v-on:keyup="validateLogin"/>
              </div>
            </div>
            <div class="field" >
              <div class="control">
                <input class="input" type="password" v-model="pass" placeholder="Password" v-on:keyup="validateLogin"/>
              </div>
            </div>
            <button class="button has-text-white-ter stblue-background is-rounded calibri is-medium m-t-25 calibri font-size-200" @click="login"> Sign in </button>
          <!-- </form> -->
        </div>
        </div>
        <!-- <div class="field">
          <label class="label has-text-white-bis" v-text="decoded"/>
        </div> -->
    </div>
    <!-- GREY BOX NAV BAR -->
    <div class="box needs-padding is-border-green has-background-grey" v-if="logged">
      <div class="container">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-one-half">
            <div class="columns is-vcentered is-mobile">
              <div class="column is-one-half">
                <!-- <label class="label is-info has-text-white">Filter by name:</label> -->
                <input class="input" type="text" v-model="filters.name.value" placeholder="Filter by name"/>
              </div>
              <div class="column is-one-half">
                <!-- <label class="label is-info has-text-white">Filter by Name:</label> -->
                <input class="input" type="text" v-model="filters.surname.value" placeholder="Filter by surname"/>
              </div>
            </div>
          </div>
          <div class="column is-one-half">
            <div class="columns is-vcentered is-mobile">
              <div class="column is-one-half">
                <!-- <label class="label is-info has-text-white">Report date:</label> -->
                <datepicker v-model="exdate" calendar-class="calendar" input-class="input" v-if="!crm"></datepicker>
              </div>
              <div class="column is-one-half">
                <div class="columns is-vcentered is-mobile">
                  <div class="column is-one-third loader-wrapper">
                    <!-- <button class="button has-text-white-ter stblue-background is-rounded is-medium m-t-25 " @click="exportLog"> Export </button> -->
                    <!-- <button class="btn btn-secondary btn-micro calibri font-size-150" @click="exportLog"> Export </button>             -->
                    <a class="calibri font-size-150" title="Download the entry and exit log of the selected date as spreadsheet" @click="exportLog" v-if="!crm && !loading">
                      <font-awesome-icon icon="file-download" />
                    </a>
                    <div class="loader is-loading" v-if="loading"></div>
                    <a class="calibri font-size-150" title="Add new user" @click="addMember" v-if="crm">
                      <font-awesome-icon icon="plus" />
                    </a>
                  </div>
                  <div class="column is-one-third">
                    <!-- <button class="button has-text-white-ter stblue-background is-rounded is-medium m-t-25 " @click="exportLog"> Export </button> -->
                    <!-- <button class="btn btn-secondary btn-micro calibri font-size-150" @click="exportLog"> Export </button>             -->
                    <a class="calibri font-size-150" title="Open User Management page" @click="openCRM">
                      <font-awesome-icon icon="users" v-if="!crm" />
                    </a>
                    <a class="calibri font-size-150" title="Open Live Status page" @click="openLiveStatus">
                      <font-awesome-icon icon="eye" v-if="crm" />
                    </a>
                  </div>
                  <div class="column is-one-third">
                    <!-- <button class="button has-text-white-ter stblue-background is-rounded is-medium m-t-25 " @click="exportLog"> Export </button> -->
                    <!-- <button class="btn btn-secondary btn-micro calibri font-size-150" @click="exportLog"> Export </button>             -->
                    <a class="calibri font-size-150" title="Sign out" @click="logOut">
                      <font-awesome-icon icon="sign-out-alt"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MEMBER CREATION / EDITION SECTION -->
    <article :class="userClass" v-if="userAction">
      <div class="message-header">
        <p>{{infoHead}}</p>
        <button class="delete" aria-label="info" @click="userAction = false; undoButton = false;"></button>
      </div>
      <div class="message-body">
        <p>{{infoMessage}}</p>
        <br>
        <button class="button is-info" aria-label="info" @click="undoDeletion" v-if="undoButton">Undo deletion</button>
      </div>
    </article>

    <!-- MEMBER CREATION BOX -->
    <div class="box needs-padding is-border-green has-background-lightgray" v-if="logged & crm & memberadmin">
      <div class="container">
        <div class="navbar-brand">
          <div class="columns">
              <div class="column is-one-quarter">
                <a class="calibri font-size-150" @click="memberadmin = false" v-if="usercreation">
                  <font-awesome-icon title="Cancel user creation and close this box" icon="times" />
                </a>
                <a class="calibri font-size-150" @click="memberadmin = false" v-if="!usercreation">
                  <font-awesome-icon title="Cancel user edition and close this box" icon="times" />
                </a>
              </div>
              <div class="column is-one-quarter">
                <a class="calibri font-size-150" @click="cancelMember" v-if="usercreation">
                  <font-awesome-icon title="Erase user data and start over" icon="eraser" />
                </a>
                <a class="calibri font-size-150" @click="deleteMember(member)" v-if="!usercreation">
                  <font-awesome-icon icon="trash-alt" title="Delete user"/>
                </a>
              </div>
              <div class="column is-one-quarter">
                <a class="calibri font-size-150" @click="saveMember" v-if="usercreation">
                  <font-awesome-icon title="Save user data" icon="save" />
                </a>
                <a class="calibri font-size-150" @click="saveEditMember" v-if="!usercreation">
                  <font-awesome-icon title="Save user data" icon="save" />
                </a>
              </div>
              <div class="column is-one-quarter
              ">
                <a class="calibri font-size-150" @click="unlinkmember(member)" v-if="!usercreation">
                  <font-awesome-icon title="Unlink user's device" icon="unlink" />
                </a>
              </div>

            </div>
        </div>
        <div class="columns is-vcentered is-mobile">
          <div class="column is-one-third">
            <label class="label is-info"> User first name </label>
            <input class="input" type="text" :required="true" placeholder="First name" v-model="member.name" input-class="text" />
          </div>
          <div class="column is-one-third">
            <label class="label is-info"> User surname </label>
            <input class="input" type="text" :required="true" placeholder="Surname" v-model="member.surname" input-class="text" />
          </div>
          <div class="column is-one-third">
            <label class="label is-info"> User group </label>
            <div class="select">
              <select title="Select user group (e.g. Employee, Contractor, Visitor)" :required="true" class="is-info" v-model="selectedTypeId" @change="selectType">
                <option v-for="type in types" v-bind:key="type.id" :value="type.id" >{{ type.name }}</option>
              </select>
            </div>
          </div>
          <!-- <div class="column is-one-quarter">

          </div> -->
        </div>
        <div class="columns is-vcentered is-mobile">
          <div class="column is-two-third">
            <label class="label is-info" > Purpose  <span class="has-text-grey-lighter is-small is-regular" v-if="member.purpose != undefined"> {{member.purpose.length}}/20</span></label>
            <input class="input" type="text" :required="false" placeholder="Purpose" v-model="member.purpose" input-class="text" @keydown="onKeyDown" />
          </div>
          <div class="column is-one-third">
            <label class="label is-info"> Mobile </label>
            <input class="input" type="text" :required="false" placeholder="Mobile" v-model="member.mobile" input-class="text" />
          </div>
          <!-- <div class="column is-one-quarter">

          </div> -->
        </div>
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            <div class="field">
              <label class="label is-info"> User email address </label>
              <p class="control has-icons-left has-icons-right">
                <input class="input" type="email" :required="true" placeholder="email address" v-model="member.email" input-class="text" />
                <span class="icon is-small is-left">
                  <font-awesome-icon title="email icon" icon="envelope" />
                </span>
              </p>
            </div>
          </div>
        </div>
          <!-- VISITOR-->
          <div class="container" v-if="typeModel == 1">
          <div class="columns is-vcentered is-mobile"  v-for="(dates, index) in visitorDates" v-bind:key="dates">
            <div class="column is-one-half" >
              <datepicker title="Pick a date for the visitor" v-model="visitorDates[index]" :required="true" calendar-class="calendar" input-class="input"></datepicker>
            </div>
            <div class="column is-one-half">
              <a class="calibri font-size-150" @click="cancelDateAddition(index)">
                <font-awesome-icon icon="times" title="Remove this date" v-if="visitorDates.length > 1" />
              </a>
            </div>
          </div>
          <div class="column is-one-half">
            <a class="calibri font-size-150" @click="addDate">
              <font-awesome-icon icon="calendar-plus" title="Add another date to this user" />
            </a>
            <!-- <button class="button has-text-white-ter stblue-background is-rounded calibri is-medium m-t-25 calibri font-size-200" @click="addDate"> Add Another Date </button> -->
          </div>
        </div>
        <!-- CONTRACTOR -->
        <div class="container" v-if="typeModel == 2">
          <div class="columns is-vcentered is-mobile">
            <div class="column is-one-half" >
              <label class="label is-info"> First Day </label>
              <datepicker title="Choose first day of contractor" :required="true" v-model="member.init" calendar-class="calendar" input-class="input"></datepicker>
            </div>
            <div class="column is-one-half" >
              <label class="label is-info"> Last Day </label>
              <datepicker title="Choose the last day of contractor" :required="true" v-model="member.end" calendar-class="calendar" input-class="input"></datepicker>
            </div>
          </div>
        </div> 
      </div>
    </div>
    <!-- LIVE STATUS TABLE BOX -->
    <div class="box needs-padding is-border-green has-background-white" v-if="logged & !crm">
      <div class="container">
        <article class="message is-info" v-if="exportempty">
          <div class="message-header">
            <p>Export file not generated</p>
            <button class="delete" aria-label="info" @click="closeDataErrorMsg"></button>
          </div>
          <div class="message-body">
            {{exportmsg}}
          </div>
        </article>
        <article class="message is-danger" v-if="exporterror">
          <div class="message-header">
            <p>Export file not generated</p>
            <button class="delete" aria-label="error" @click="closeDataErrorMsg"></button>
          </div>
          <div class="message-body">
            {{exportmsg}}
          </div>
        </article>
        <article class="message is-danger" v-if="dataerror">
          <div class="message-header">
            <p>Error at retrieving data</p>
            <button class="delete" aria-label="error" @click="closeDataErrorMsg"></button>
          </div>
          <div class="message-body">
            Oops, it looks like there has been an error, retrieving the data, please try again later
          </div>
        </article>
        <div class="table__wrapper">
          <v-table class="table" title="Members" :data="members" :filters="filters">
          <thead slot="head">
            <v-th sortKey="name">Name</v-th>
            <v-th sortKey="surname">Surname</v-th>
            <!-- <v-th sortKey="memberId">Staff Number</v-th> -->
            <v-th sortKey="memberType">Type</v-th>
            <v-th sortKey="accessTime" defaultSort="desc">Access Time</v-th>
            <th>Purpose</th>
          </thead>
          <tbody slot="body" slot-scope="{displayData}">
            <tr v-for="row in displayData" :key="row.id">
              <td>{{row.name}}</td>
              <td>{{row.surname}}</td>
              <!-- <td>{{row.memberId}}</td> -->
              <td>{{row.memberType}}</td>
              <td>{{row.accessTime}}</td>
              <td>{{row.purpose}}</td>
            </tr>
          </tbody>
        </v-table>
        </div>
        </div>
    </div>
    <!-- MEMBER TABLE BOX -->
    <div class="box needs-padding is-border-green has-background-white" v-if="logged & crm">
      <div class="container">
        <div class="table__wrapper">
          <v-table class="table" title="Members" :data="membersCRM" 
          :filters="filters" :currentPage.sync="currentPage" :pageSize="20"
          @totalPagesChanged="totalPages = $event">
            <thead slot="head">
              <th>Edit</th>
              <v-th sortKey="name" defaultSort="desc">Name</v-th>
              <v-th sortKey="surname">Surname</v-th>
              <v-th sortKey="email">Email</v-th>
              <!-- <v-th sortKey="memberId">Staff Number</v-th> -->
              <!-- <v-th sortKey="temporal">Temporary</v-th> -->
              <v-th sortKey="type"> Type</v-th>
              <th> Purpose</th>
              <th> Mobile</th>
            </thead>
            <tbody slot="body" slot-scope="{displayData}">
              <tr v-for="row in displayData" :key="row.id">
                <td>            
                  <a class="calibri font-size-100" @click="editMember(row)">
                    <font-awesome-icon icon="edit" title="Edit user information" />
                  </a>
                </td>
                <td>{{row.name}}</td>
                <td>{{row.surname}}</td>
                <td>{{row.email}}</td>
                <!-- <td>{{row.memberId}}</td> -->
                <!-- <td>{{row.temporal ? "Yes" : "No"}}</td> -->
                <td>{{getTypeName(row.type) }}</td>
                <td>{{row.purpose}}</td>
                <td>{{row.mobile}}</td>
                <td>            
                  <a class="calibri font-size-100" @click="editMember(row)">
                    <font-awesome-icon icon="edit" title="Edit user information" />
                  </a>
                </td>
              </tr>
            </tbody>
          </v-table>
          <smart-pagination
            :currentPage.sync="currentPage"
            :totalPages="totalPages"
          />
        </div>
      </div>
    </div>
    <!-- FOOTER -->
    <div class="container">
      <div class="field" >
        <label class="stblue-foreground calibri"> © Copyright {{year}} CCTech Ltd | All Rights Reserved </label>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import axios from 'axios';
import { setInterval, clearInterval } from 'timers';
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker
  },
  data(){
    return{
      user: '',
      pass: '',
      token: '',
      logged: false,
      crm: false,
      memberadmin: false,
      members: [],
      membersCRM: [],
      logerror: false,
      dataerror: false,
      interval: 0,
      intervalCRM: 0,
      year: 2020,
      exdate: '',
      headline: 'Welcome to Smart Tile Plus',
      exportmsg: 'No events found at selected date, please try another date.',
      exportempty: false,
      exporterror: false,
      member: {
        id: 0,
        name: "",
        surname: "",
        memberId: "",
        location: 0,
        group: 0,
        type: 0,
        allowance: true,
        init: "",
        end: "",
        extrafields: [],
        comments: [],
        temporal: false,
        email: "",
        purpose: "",
        mobile: "",
      },
      types:[],
      selectedType: '',
      typeModel: 0,
      selectedTypeId: 0,
      visitorDates:[],
      infoMessage: '',
      infoHead: '',
      userAction: false,
      userClass: "message is-info",
      usercreation: false,
      memberbackup: {
        id: 0,
        name: "",
        surname: "",
        memberId: "",
        location: 0,
        group: 0,
        type: 0,
        allowance: true,
        init: "",
        end: "",
        extrafields: [],
        comments: [],
        temporal: false,
        email: "",
        purpose: "",
        mobile: "",
      },
      visitorDatesBackup: [],
      url: "https://api-ha.smarttile.co.uk/",
      // url: "https://localhost:44307/",
      filters: { 
        name: {value: '', keys:['name']},
        surname: {value: '', keys:['surname']},
      },
      currentPage: 1,
      totalPages: 0,
      loading: false,
      deletedUser: 0,
      undoButton: false,
      askpass: false,
      uguid: '',
    };
  },
  created(){
    let dt = new Date();
    let dtnext = new Date();
    this.exdate = dt;
    this.year = this.exdate.getFullYear();
    this.visitorDates.push(dt);
    this.member.init = dt;
    this.member.end = new Date(dtnext.setMonth(dtnext.getMonth() + 1));
    this.$eventHub.$on('event-changed', this.onEventChanged);
  },
  beforeDestroy()
  {
    clearInterval(this.interval);
    clearInterval(this.intervalCRM);

  },
  methods:{
    onKeyDown(evt){
      if(this.member.purpose != undefined){
        if (this.member.purpose.length >= 20) {
          if (evt.keyCode >= 48 && evt.keyCode <= 90) {
            evt.preventDefault()
            return
          }
        }
      }

    },
    validateLogin(e){
      if (e.keyCode === 13) {
        if(this.user !== '' && this.pass !== ''){
          this.login();
        }
      }
    },
    login(){
      const auth = {
        headers: {token:'56u589gf648745!ji32'} 
      }
      // if(this.askpass){
      axios.get(this.url + "api/stplus/AuthEndPoint?user=" + this.user + "&pass=" + this.pass, auth)
        .then((res) => {
          if(res.status === 200){
            if(res.data === null){
              this.logerror = true;
            }
            else{
              this.token = res.data;
              this.retriveLiveData();
              this.showMembers();
            }
          }
          else{
            this.logerror = true;            
          }
        }).catch((error) => {
          console.log(error);
          this.logerror = true;
          // this.error = "Error at getting your passes"
      })
      // }
      // else{

      // }
    },
    retriveLiveData(){
      axios.get(this.url + "api/stplus/getcsmembers?token=" + this.token)
      .then((res) => {
        if(res.status === 200){
          if(res.data === null){
            this.dataerror = true;
          }
          else{
            this.members = res.data;
          }
        }
        else{
          this.dataerror = true;            
        }
      }).catch((error) => {
        console.log(error);
        this.logerror = true;
        // this.error = "Error at getting your passes"
      })
    },
    showMembers(){
      this.logged = true;
      this.headline = 'Live Status';
      this.interval = setInterval(function(){
        this.retriveLiveData();
      }.bind(this),60000)

    },
    onEventChanged(){
      this.retriveLiveData();
    },
    exportLog(){
      this.loading = true;
      this.logged = true;
        // let strdate = this.exdate.getFullYear() + '-' + ("0" + (this.exdate.getMonth() + 1)).slice(-2) + '-' + this.exdate.getDate();
        let strdate = this.exdate.getFullYear() + '-' + this.getTwoDigitDateFormat(this.exdate.getMonth()+1) + '-' + this.getTwoDigitDateFormat(this.exdate.getDate());
        // console.log(strdate)
        let url = this.url + "api/stplus/GetCSMmembersLog?token=" + this.token + "&date=" + strdate;
        axios.get(url)
        .then((res) => {
          if(res.status === 200){
            window.open(url,"_self")
            this.loading = false;
          }
          else if(res.status === 204){
            this.exportmsg = 'No events found at selected date, please try another date.';
            this.exportempty = true;
            this.loading = false;
          }
        }).catch((error) => {
          console.log(error);
          this.logerror = true;
          // this.error = "Error at getting your passes"
          this.exportmsg = 'Error at generating the report file. Please check the date is correct or refresh the page and log in again.';
          this.exporterror = true;
          this.loading = false;
      })
    },
    getTwoDigitDateFormat(monthOrDate) {
      return (monthOrDate < 10) ? '0' + monthOrDate : '' + monthOrDate;
    },
    retrieveMembers(){
      axios.get(this.url + "api/stplus/getmembertypes?token=" + this.token)
        .then((res) => {
          if(res.status === 200){
            if(res.data === null){
              this.dataerror = true;
            }
            else{
              this.types = res.data;
                axios.get(this.url + "api/stplus/getmembers?token=" + this.token)
                .then((resp) => {
                  if(resp.status === 200){
                    if(resp.data === null){
                      this.dataerror = true;
                    }
                    else{
                      this.membersCRM = resp.data;
                    }
                  }
                  else{
                    this.dataerror = true;            
                  }
                }).catch((error) => {
                  console.log(error);
                  this.logerror = true;
                  // this.error = "Error at getting your passes"
              })
            }
          }
          else{
            this.dataerror = true;            
          }
        }).catch((error) => {
          console.log(error);
          this.logerror = true;
          // this.error = "Error at getting your passes"
      })
    },
    openCRM() {
      this.crm = true;
      this.headline = 'User Management';
      this.retrieveMembers();
      this.intervalCRM = setInterval(function(){
        this.retrieveMembers();
      }.bind(this),60000)

    },
    openLiveStatus(){
      this.crm = false;
      this.memberadmin = false;
      this.headline = 'Live Status';
      this.userAction = false;
      this.retriveLiveData();
      this.showMembers();
    },
    closeLogErrorMsg(){
      this.logerror = false;
    },
    closeDataErrorMsg(){
      this.dataerror = false;
      this.exportempty = false;
      this.exporterror = false;
    },
    addMember(){
      //CREATE MEMBER
      this.memberadmin = true;
      this.usercreation = true;
      this.refreshMember();
      this.typeModel = 0;
      this.selectedTypeId = 0;
      this.visitorDates = [ new Date()];
    },
    editMember(member){
      //EDIT MEMBER
      this.memberadmin = true;
      this.member = member;
      this.memberbackup = member;
      this.usercreation = false;
      // let visitor;
      this.types.forEach(type => {
        if(type.id == member.type){
          this.selectedTypeId = type.id;
          this.selectedType = type.name;
          this.typeModel = type.model;
        }
      });
      window.scrollTo(0, 0);
      if(member.type == this.selectedTypeId){
        axios.get(this.url + "api/stplus/GetAccessTimesforMember?token=" + this.token + "&member=" + member.id)
        .then((res) => {
          if(res.status === 200){
            this.visitorDates = res.data;
            this.visitorDatesBackup = res.data;

          }
        })
        .catch((error) => {
          console.log(error);
        })
      }
    },
    deleteMember(member){
      //Delete Member
      if(confirm("Do you really want to delete " + member.name + " " + member.surname + "?")){
        axios.delete(this.url + "api/stplus/deletewebmember?token=" + this.token + "&id=" + member.id + "&name=" + member.name)
        .then((res) => {
          if(res.status === 204){
            this.userCreationMessage("message is-success", "User deleted successfully", "User deleted");
            this.deletedUser = member.id;
            this.undoButton = true;
            this.memberadmin = false;
            this.retrieveMembers();
          }
          else{
            this.userCreationMessage("message is-danger", "Could not delete the user, please refresh the page and log in again", "User not deleted");
            this.retrieveMembers();
          }
        }).catch((error) => {
          console.log(error);
          this.userCreationMessage("message is-danger", "Could not delete the user, please refresh the page and log in again", "User not deleted");
          this.retrieveMembers();
        })
      }
    },
    unlinkmember(member){
      //Delete Member
      if(confirm("Do you really want to unlink the device for " + member.name + " " + member.surname + "?")){
        axios.delete(this.url + "api/stplus/unlinkdeviceemail?token=" + this.token + "&id=" + member.id + "&name=" + member.name)
        .then((res) => {
          if(res.status === 204){
            this.userCreationMessage("message is-success", "Device unlinked for member successfully", "Device unlinked");
            this.openCRM();
          }
          else{
            this.userCreationMessage("message is-danger", "Could not unlink user's device, please refresh the page and log in again", "Device not unlinked");
            this.openCRM();
          }
        }).catch((error) => {
          console.log(error);
          this.userCreationMessage("message is-danger", "Could not unlink user's device, please refresh the page and log in again", "Device not unlinked");
          this.openCRM();
        })
      }
    },
    undoDeletion(){
      axios.post(this.url + "api/stplus/UndoWebDeletion?token=" + this.token + "&member=" + this.deletedUser)
      .then((res) => {
        if(res.status === 201){
          this.userCreationMessage("message is-info", "User deletion undone succesfully", "User not deleted");
          this.undoButton = false;
          this.openCRM();
        }
        else{
          this.userCreationMessage("message is-danger", "Could not undo de user deletion unfortunately", "Deletion not undone");
          this.undoButton = false;
          this.openCRM();
        }
      }).catch((error) => {
        console.log(error);
        this.userCreationMessage("message is-danger", "Could not undo de user deletion unfortunately", "Deletion not undone");
        this.undoButton = false;
        this.openCRM();
      })
    },
    validEmail(email){
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    saveMember(){
      //Save creation and edit member
      this.undoButton = false;
      const config = { headers: { 'token' : this.token} };
      if(this.selectedTypeId == 0){
        this.userCreationMessage("message is-danger", "Please select user group", "Need user group");
        return;
      }
      if(!this.validEmail(this.member.email))
      {
        this.userCreationMessage("message is-danger", "The email address " + this.member.email + " given is not recognized as a real email address", "Wrong email address");
        return;
      }
      if(this.typeModel == 2){
        let init = new Date(this.member.init);
        let end = new Date(this.member.end);
        if(init > end){
          this.userCreationMessage("message is-danger", "The first day cannot be later than the last day", "First day later than last day");
          return;
        }
      }
      this.member.type = this.selectedTypeId;
      this.member.temporal = this.typeModel != 0;
      this.member.init = new Date(new Date(this.member.init).getTime() - new Date(this.member.init).getTimezoneOffset()*60*1000);
      this.member.end = new Date(new Date(this.member.end).getTime() - new Date(this.member.end).getTimezoneOffset()*60*1000);
      axios.post(this.url + "api/stplus/createwebmember", this.member, config)
      .then((res) => {
        if(res.status === 200){
          if(res.data === null){
            this.dataerror = true;
          }
          else{
            if(res.data == 0){
              this.userCreationMessage("message is-danger", "Could not create the user. Check the given data is correct, no field is empty and the user does not exist already in the database", 
              "User not created");
              return;
            }
            else if(res.data == -1){
              //Email already exists
              this.userCreationMessage("message is-danger", "The email address " + this.member.email + " already exists", "Email already exists");
              return;
            }
            this.member.id = res.data;
            if(this.typeModel == 1){
              const datesConfig = { headers: { 'token': this.token, 'member': this.member.id} };
              axios.post(this.url + "api/stplus/addaccesstimestomember", this.visitorDates, datesConfig)
                .then((resp) => {
                  if(resp.status === 201){
                    if(resp.data === null){
                      this.dataerror = true;
                    }
                    else{
                      this.userCreationMessage("message is-success", "Member " + this.member.name + " " + this.member.surname + " created successfully", 
                      "Member created");
                      this.memberadmin = false;
                      this.refreshMember();
                      this.openCRM();
                    }
                  }
                  else{
                    this.dataerror = true;            
                  }
                }).catch(() => {
                  // console.log(error);
                  this.logerror = true;
                  // this.error = "Error at getting your passes"
              })
            }
            else{
              this.userCreationMessage("message is-success", "Member " + this.member.name + " " + this.member.surname + " created successfully", 
              "Member created");
              this.memberadmin = false;
              this.refreshMember();
              this.openCRM();
            }
          }
        }
        else{
          this.dataerror = true;            
        }
      }).catch(() => {
        // console.log(error);
        this.logerror = true;
        // this.error = "Error at getting your passes"
    })
    },
    saveEditMember(){
      this.undoButton = false;
      //Save edit member
       const config = { headers: { 'token' : this.token} };
      if(this.selectedTypeId == 0){
        this.userCreationMessage("message is-danger", "Please select user group", "Need user group");
        return;
      }
      if(!this.validEmail(this.member.email))
      {
        this.userCreationMessage("message is-danger", "The email address " + this.member.email + " given is not recognized as a real email address", "Wrong email address");
        return;
      }
      if(this.typeModel == 2){
        let init = new Date(Date.UTC(this.member.init));
        console.log(this.member.end);
        let end = this.member.end;
        console.log(end);
        // end.setTime(end.getTime() - end.getTimezoneOffset()*60*100);
        if(init > end){
          this.userCreationMessage("message is-danger", "The first day cannot be later than the last day", "First day later than last day");
          return;
        }
      }
      if(confirm("Do you really want to edit " + this.member.name + " " + this.member.surname + "?")){
        this.member.type = this.selectedTypeId;
        this.member.temporal = this.typeModel != 0;
        this.member.init = new Date(new Date(this.member.init).getTime() - new Date(this.member.init).getTimezoneOffset()*60*1000);
        this.member.end = new Date(new Date(this.member.end).getTime() - new Date(this.member.end).getTimezoneOffset()*60*1000);
        axios.put(this.url + "api/stplus/editwebmember", this.member, config)
        .then((res) => {
          if(res.status === 200){
            if(this.typeModel == 1){
              const datesConfig = { headers: { 'token': this.token, 'member': this.member.id} };
              axios.post(this.url + "api/stplus/editaccesstimestomember", this.visitorDates, datesConfig)
                .then((resp) => {
                  if(resp.status === 201){
                    if(resp.data === null){
                      this.dataerror = true;
                    }
                    else{
                      this.userCreationMessage("message is-success", "Member " + this.member.name + " " + this.member.surname + " updated successfully", 
                      "Member updated");
                      this.memberadmin = false;
                      this.refreshMember();
                      this.openCRM();
                    }
                  }
                  else{
                    this.dataerror = true;            
                  }
                }).catch((error) => {
                  console.log(error);
                  this.logerror = true;
                  // this.error = "Error at getting your passes"
              })
            }
            else{
              axios.delete(this.url + "api/stplus/deleteaccesstimesmember?token=" + this.token + "&member=" + this.member.id)
              .then(() => {

              }).catch(() => {
                // console.log(error);
              })
              this.userCreationMessage("message is-success", "Member " + this.member.name + " " + this.member.surname + " updated successfully", 
              "Member updated");
              this.memberadmin = false;
              this.refreshMember();
              this.openCRM();
            }
          }
          else if(res.status === 303){
            //Email already exists
            this.userCreationMessage("message is-danger", "The email address " + this.member.email + " already exists for another user.", "Email already exists");
            return;
          }
        }).catch((error) => {
          // console.log(error);
          this.logerror = true;
          if(error.response.status === 303){
            //Email already exists
            this.userCreationMessage("message is-danger", "The email address " + this.member.email + " already exists for another user.", "Email already exists");
            return;
          }
          // this.error = "Error at getting your passes"
        })
      }
    },
    userCreationMessage(uclass, message, header){
      this.userAction = true;
      this.userClass = uclass;
      this.infoMessage = message;
      this.infoHead = header;
    },
    cancelMember(){
      //Cancel Member creation and edition
      this.refreshMember();
      this.typeModel = 0;
      this.selectedTypeId = 0;
      this.visitorDates = [ new Date()];
    },
    cancelEditMember(){
      this.member = this.memberbackup;
      this.visitorDates = this.visitorDatesBackup;
    },
    addDate(){
      // console.log(this.visitorDates);
      this.visitorDates.push(new Date());
    },
    cancelDateAddition(index){
      this.visitorDates.splice(index, 1);
    },
    getTypeName(id){
      var i;
      for(i = 0; i < this.types.length; i++){
        if(this.types[i].id == id){
          return this.types[i].name;
        }
      }
    },
    selectType(){
      // console.log(this.selectedTypeId)
      // console.log(this.member)
      this.types.forEach(type => {
        if(type.id == this.selectedTypeId){
          this.typeModel = type.model;
        }
      });
      if(this.typeModel == 1 && this.visitorDates.length == 0){
        this.visitorDates.push(new Date());
        this.visitorDates.visitorDatesBackup = this.visitorDates;
      }
      // let aux = this.selectedType;
      // this.typeModel = aux[0];
      // this.selectedTypeId = aux[1];
      // console.log(aux[0])
      // console.log(aux[1])
      // console.log(this.selectedTypeId)
      // console.log(this.member)
    },
    refreshMember(){
      let dt = new Date();
      let dtnext = new Date();
      this.visitorDates = [];
      this.visitorDates.push(dt);
      this.member = {
        id: 0,
        name: "",
        surname: "",
        memberId: "",
        location: 0,
        group: 0,
        type: 0,
        allowance: true,
        init: new Date(),
        end: new Date(dtnext.setMonth(dtnext.getMonth() + 1)),
        extrafields: [],
        comments: [],
        temporal: false
      };
    },
    logOut(){
      window.location.reload();
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #00cdef;
}
font-awesome-icon{
  color: #00cdef;
}
.stblue-background {
  background-color: #080035;
}
.stblue-foreground{
  color: #080035;
}
.white-background{
  background-color: #fff; 
}
.calibri {
  font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  font-weight: normal;
}
.font-size-200{
  font-size: 200%;
}
.font-size-150{
  font-size: 150%;
}
.calibri-bold{
  font-weight: bold;
}
.m-t-25{
  margin: 25px;
}
.m-t-12{
  margin: 12px;
}
.table {
      margin-left: auto;
      margin-right: auto;
    }
    .table__wrapper {
  overflow-x: auto;
}
.vt-sort:before{
    font-family: FontAwesome;
    padding-right: 0.5em;
    width: 1.28571429em;
    display: inline-block;
    text-align: center;
}
</style>
