import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
export default {
  install (Vue) {
    const connection = new HubConnectionBuilder()
      .withUrl('https://api-ha.smarttile.co.uk/stplivehub')
      // .withUrl('https://localhost:44307/stplivehub')
      .configureLogging(LogLevel.Information)
      .build()

    // use new Vue instance as an event bus
    const eventHub = new Vue() 
    // every component will use this.$questionHub to access the event bus
    Vue.prototype.$eventHub = eventHub
    // Forward server side SignalR events through $questionHub, where components will listen to them
    connection.on('STLiveEvent', (eid) => {
        eventHub.$emit('event-changed', {eid})
    })
 
    let startedPromise = null
    function start () {
      startedPromise = connection.start().catch(err => {
        console.error('Failed to connect with hub', err)
        return new Promise((resolve, reject) => 
          setTimeout(() => start().then(resolve).catch(reject), 5000))
      })
      return startedPromise
    }
    connection.onclose(() => start())
    
    start()
  }
  
}